<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card card-custom card-stretch gutter-b">
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
            <span class="card-label font-weight-bolder text-dark"
            >Reiniciar el training del alumno</span
            >
                    </h3>
                    <div class="card-toolbar">
                        <a
                                @click="goList"
                                class="btn btn-secondary font-weight-bolder font-size-sm mr-3"
                        >
                            Volver
                        </a>
                    </div>
                </div>
                <div class="card-body pb-10 pb-2">
                    <div class="row ml-2 mt-2">
                        <div class="col-md-4">
                            <div class="label-detail">Rut</div>
                            <div>{{ selectedItem.fullRutWithHypend }}</div>
                        </div>
                        <div class="col-md-4">
                            <div class="label-detail">Nombre y apellidos</div>
                            <div>{{ selectedItem.name }}&nbsp;{{ selectedItem.last_name }}</div>
                        </div>
                    </div>
                    <div class="row ml-2 mt-2">
                        <div class="col-md-4">
                            <div class="label-detail">Empresa</div>
                            <div>{{ selectedItem.order.enterprise.razon_social_empresa }}</div>
                        </div>
                        <div class="col-md-4">
                            <div class="label-detail">Training</div>
                            <div>{{ selectedItem.order.training.name }}</div>
                        </div>
                    </div>
                    <div class="row ml-2 mt-2">
                        <div class="col-md-4">
                            <div class="label-detail">Correo empresa</div>
                            <div>{{ selectedItem.email }}</div>
                        </div>
                        <div class="col-md-4">
                            <div class="label-detail">Teléfono empresa</div>
                            <div>{{ selectedItem.phone }}</div>
                        </div>
                    </div>
                    <hr/>
                    <div class="row ml-2 mt-2">
                        <div class="col-md-4">
                            <div class="label-detail">Selección</div>
                            <ul v-if="selectedItem.course_selection.length > 0">
                                <li v-for="course in selectedItem.course_selection" :key="course">
                                    {{ course.name }}
                                </li>
                            </ul>
                            <div v-else>Sin cursos</div>
                        </div>
                        <div class="col-md-4">
                            <div class="label-detail">Estado</div>
                            <div>{{ selectedItem.traininginfo.status.name }}</div>
                        </div>
                        <div class="col-md-4">
                            <div class="label-detail">Descripción</div>
                            <div>{{ selectedItem.traininginfo.status.description }}</div>
                        </div>
                    </div>
                    <hr/>
                    <div class="row ml-2 mt-2">
                        <div class="col-md-12 text-right">
                            <b-button type="button" variant="primary" v-b-modal.modal-save>Reiniciar
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="modal-save" title="¿Estas seguro?">
            Se van a reiniciar los datos del alumno
            <template #modal-footer="{ ok, cancel, hide }">
                <b-button size="sm" variant="primary" @click="cancel()">
                    Cancelar
                </b-button>
                <b-button size="sm" variant="danger" @click="modifyItem(), ok()">
                    Aceptar
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import {STUDENT_RESET} from "@/store/modules/students.module";

    export default {
        name: "StudentDetail",
        data() {
            return {
                isLoading: false
            };
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Alumnos", route: "students"},
                {title: "Reiniciar training"},
            ]);
        },
        methods: {
            goList() {
                this.$router.push("students");
            },
            modifyItem() {
                this.$store.dispatch(STUDENT_RESET, this.selectedItem)
                    .then(() => {
                        this.$router.push("students")
                    })
            }
        },
        computed: {
            selectedItem() {
                return this.$store.state.students.selectedStudent;
            },
        },
    };
</script>

<style scoped></style>
